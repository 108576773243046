import React, { useState, useEffect } from "react";
import axios from "axios";

export default function SelectCities({ form, setForm }) {
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator
  const [searchResults, setSearchResults] = useState([]); // State for search results
  const [searchTerm, setSearchTerm] = useState(""); // State for search input

  const fetchCities = async (searchTerm) => {
    if (!searchTerm || searchTerm.length === 0) {
      setSearchResults([]);
      return;
    }

    setIsLoading(true); // Show loading indicator
    try {
      const apiUrl = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
        searchTerm
      )}&format=jsonv2&addressdetails=1`;
      const response = await axios.get(apiUrl);
      const citiesData = response?.data;
      const options = [
        { value: "All", label: "All" }, // Add "All" option
        ...citiesData
          ?.filter((city) => city?.address?.city) // Filter out entries without a city
          .map((city) => {
            console.log("city", city);
            return {
              value: city?.address?.city,
              label: city?.display_name,
            };
          }),
      ];

      // Convert form.cities into the format react-select expects for the value prop
      // const selectedCities = form.cities.map((city) => ({
      //   value: city,
      //   label: city,
      // }));
      console.log("options", options);
      setSearchResults(options || []);
    } catch (error) {
      console.error("Error fetching data from OpenStreetMap API", error);
    } finally {
      setIsLoading(false); // Hide loading indicator
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    fetchCities(value);
  };

  const handleSelect = (city) => {
    // Add the selected city to the form and reset searchTerm
    if (!form.cities.includes(city)) {
      setForm((prevForm) => ({
        ...prevForm,
        cities: [...prevForm.cities, city],
      }));
    }
    setSearchTerm("");
    setSearchResults([]);
  };

  const handleRemove = (city) => {
    // Remove the city from the selected list
    setForm((prevForm) => ({
      ...prevForm,
      cities: prevForm.cities.filter((c) => c !== city),
    }));
  };
  console.log("searchResults", searchResults);
  return (
    <div style={{ position: "relative", width: "100%" }}>
      {isLoading && <div>Loading...</div>}
      {searchResults.length > 0 && (
        <ul
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            right: 0,
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            borderRadius: "8px",
            maxHeight: "200px",
            overflowY: "auto",
            zIndex: 1000,
            listStyle: "none",
            padding: 0,
            margin: "4px 0 0 0",
            width: "100%",
          }}
        >
          {searchResults?.map((city, index) => (
            <li
              key={index}
              onClick={() => handleSelect(city?.value)}
              style={{
                padding: "10px",
                cursor: "pointer",
              }}
            >
              {city.label}
            </li>
          ))}
        </ul>
      )}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginBottom: "10px",
          gap: "8px",
        }}
      >
        {form?.cities?.map((city, index) => (
          <div
            key={index}
            style={{
              padding: "8px 12px",
              borderRadius: "16px",
              backgroundColor: "#ECF1F4",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {city}
            <button
              onClick={() => handleRemove(city)}
              style={{
                border: "none",
                background: "none",
                cursor: "pointer",
                color: "#ff4d4d",
              }}
            >
              &times;
            </button>
          </div>
        ))}
      </div>
      <input
        type="text"
        placeholder="Search for a city"
        value={searchTerm}
        onChange={handleSearch}
        style={{
          width: "100%",
          padding: "8px 16px",
          borderRadius: "16px",
          border: "1px solid #ccc",
          backgroundColor: "#ECF1F4",
        }}
      />
    </div>
  );
}
