import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { Td, Tr } from "@chakra-ui/react";
import { FaUser } from "react-icons/fa";
import DeleteMembership from "./Cancel/DeleteMembership";
import CancelMembership from "./Cancel/CancelMembership";
import styles from "../styles.module.css";
import { userRequest } from "../../../requestMethod";

const ActiveTable = ({
  el,
  handleContact,
  handleProfile,
  handleView,
  convertDate,
  updateData,
}) => {
  const [status, setStatus] = useState("Loading...");

  // Helper function to determine subscription status
  const determineStatus = async (subscription) => {
    if (!subscription) return "Inactive";

    const { usedVoucher, isCancel, isActive, endAt } = subscription;

    if (usedVoucher) {
      try {
        const { data } = await userRequest.get(
          `/admin/voucher/getSingleUsedVoucher?voucher=${usedVoucher}`
        );
        const percentage = data?.data?.percentage;

        if (
          percentage &&
          [
            "1 month free",
            "3 months free",
            "6 months free",
            "12 months free",
          ].some((trial) => percentage.includes(trial)) &&
          !isCancel
        ) {
          return "Free trial";
        }
      } catch (error) {
        console.error("Error fetching voucher:", error);
        return "Error";
      }
    }

    if (isCancel) {
      return `Ends on ${new Date(endAt).toLocaleDateString()}`;
    }

    if (isActive) {
      return "Active";
    }

    return "Inactive";
  };

  useEffect(() => {
    const subscription = el?.partnerSubscriptions;
    determineStatus(subscription).then(setStatus);
  }, [el?.partnerSubscriptions]);

  // Rendered address rows
  const addressRows = useMemo(
    () =>
      el?.address
        ? el.address.split(",").map((row, idx) => <p key={idx}>{row}</p>)
        : "...",
    [el?.address]
  );
  console.log("addressRows", addressRows);
  console.log("el?.address", el?.address);
  return (
    <Tr key={el?._id}>
      <Td className={styles.tableHead}>{el?.name}</Td>
      <Td className={styles.tableHead}>
        <div className={styles.address}>
          <p
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <span>{el?.addressObject?.street}, </span>
            <span>{el?.addressObject?.house_number}</span>
          </p>
          <p
            style={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <span>{el?.addressObject?.postcode},</span>
            <span>{el?.addressObject?.city}</span>
          </p>
          <p>{el?.addressObject?.country}</p>
        </div>
      </Td>
      <Td className={styles.tableHead}>
        {/* <Link to={`/Partners/${el?.partnerId?._id}?from=Active partner`}> */}
        <p
          className={styles.blackBtn}
          onClick={() => handleContact(el?.partnerId?._id, "Active Partners")}
        >
          Contact Details
        </p>
        {/* </Link> */}
      </Td>
      <Td className={styles.tableHead}>
        <p className={styles.blackBtn29} onClick={() => handleView(el._id)}>
          View
        </p>
      </Td>
      <Td className={styles.tableHead}>{convertDate(el.createdAt)}</Td>
      <Td className={styles.tableHead}>{status}</Td>
      <Td className={styles.tableHead}>
        {el?.partnerSubscriptions?.usedVoucher ?? "N/A"}
      </Td>
      <Td className={styles.tableHead}>{el.subscription?.planName ?? "N/A"}</Td>
      <Td className={styles.tableHead}>{el.category}</Td>
      <Td style={{ minWidth: "180px", maxWidth: "180px" }}>
        <div className={styles.actionDivMain}>
          <div className={styles.actionDiv2}>
            <p
              className={styles.blackBtn10}
              onClick={() => handleProfile(el.partnerId._id)}
            >
              <FaUser />
            </p>
            <DeleteMembership
              partnerId={el.partnerId._id}
              partner={el}
              updateData={updateData}
            />
          </div>
          {!el?.partnerSubscriptions?.isCancel && (
            <CancelMembership
              partnerId={el.partnerId._id}
              partner={el}
              updateData={updateData}
            />
          )}
        </div>
      </Td>
    </Tr>
  );
};

export default ActiveTable;
