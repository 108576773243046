import React, { useState } from "react";
import styles from "../style.module.css";
import DeleteModel from "../DeleteModel2";
import { Pagination } from "antd";
import Terminate from "../Terminate";
import OverviewPreview from "./OverviewPreview";
import { Spinner, useDisclosure } from "@chakra-ui/react";
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

const Overview = ({ ads, total, page, setPage, loading, type }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedAdId, setSelectedAdId] = useState(null);
  const { isOpen, onClose, onOpen } = useDisclosure();
  if (!loading && ads.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          marginTop: "10%",
        }}
      >
        No data available
      </div>
    );
  }
  const openModal = (id) => {
    setSelectedAdId(id?._id);
    setIsModalVisible(true);
    onOpen();
  };
  console.log("isModalVisible", isModalVisible);
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedAdId(null);
    onClose();
  };
  return (
    <div className={styles.tableDiv}>
      <TableContainer
        style={{ border: "1px solid #D9E1E7", borderRadius: "20px" }}
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th style={{ textTransform: "capitalize" }}>Partner Name</Th>
              <Th style={{ textTransform: "capitalize" }}>Ad Name</Th>
              <Th style={{ textTransform: "capitalize" }}>Ad Title</Th>
              <Th style={{ textTransform: "capitalize" }}>Ad body</Th>
              <Th style={{ textTransform: "capitalize" }}>Ad Type</Th>
              <Th style={{ textTransform: "capitalize" }}>Release on</Th>
              <Th style={{ textTransform: "capitalize" }}>Created on</Th>
              <Th style={{ textTransform: "capitalize" }}>status</Th>
              <Th style={{ textTransform: "capitalize" }}>Action</Th>
            </Tr>
          </Thead>
          {loading ? (
            <span className={styles.spin}>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="lg"
              />
            </span>
          ) : (
            <Tbody>
              {ads.map &&
                ads.map((el, i) => {
                  return (
                    <Tr key={i} onClick={() => openModal(el)}>
                      <Td>
                        {el.partnerBusiness
                          ? `${el.partnerBusiness?.name}`
                          : "-"}
                      </Td>
                      <Td>{el.name ? el.name : "-"}</Td>
                      <Td>{el.title ? el.title : "-"}</Td>
                      <Td>{el.body ? el.body : "-"}</Td>
                      <Td>{el.adType ? el.adType : "-"}</Td>
                      <Td>
                        {el.releaseDate ? formatDate(el.releaseDate) : "-"}
                      </Td>
                      <Td>{el.createdAt ? formatDate(el.createdAt) : "-"}</Td>
                      <Td>
                        <p className={styles.blackBtn21}>{getStatus(el)}</p>
                      </Td>
                      <Td>
                        {getStatus(el)?.props?.children === "Running" ? (
                          <Terminate
                            el={el}
                            type={type}
                            page={page}
                            partnerAd={true}
                          />
                        ) : (
                          <DeleteModel
                            el={el}
                            type={type}
                            page={page}
                            partnerAd={true}
                          />
                        )}
                      </Td>
                    </Tr>
                  );
                })}
              {/* {isModalVisible && <OverviewPreview date={ads[0]} />} */}
            </Tbody>
          )}
        </Table>
        {isModalVisible && (
          <OverviewPreview
            id={selectedAdId}
            isModalVisible={isModalVisible}
            isOpen={isOpen}
            onClose={closeModal}
          />
        )}
      </TableContainer>
      {total > 10 && (
        <div className={styles.pagination}>
          <Pagination
            defaultCurrent={1}
            total={total}
            onChange={(e) => setPage(e)}
            showSizeChanger={false}
          />
        </div>
      )}
    </div>
  );
};

export default Overview;

function formatDate(dateStr) {
  try {
    const dateObj = new Date(dateStr);
    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = dateObj.toLocaleDateString("en-US", options);
    return formattedDate;
  } catch (error) {
    return null;
  }
}

// const getStatus = (el) => {
//   const currentDate = new Date();
//   const releaseDateOnly = el.releaseDate.split("T")[0];
//   console.log("releaseDateOnly", releaseDateOnly);
//   const releaseDateTime = new Date(`${releaseDateOnly}`);
//   if (currentDate < releaseDateTime) {
//     return "Starting Soon";
//   } else {
//     return "Running";
//   }
// };

const getStatus = (el) => {
  const currentDate = new Date();
  const releaseDateOnly = el.releaseDate.split("T")[0];
  const releaseDateTime = new Date(`${releaseDateOnly}`);
  if (currentDate < releaseDateTime) {
    return (
      <p
        style={{
          backgroundColor: "#d2eef3",
          color: "#277eac",
          padding: "5px 15px 5px 15px",
          borderRadius: "8px",
        }}
      >
        Starting Soon
      </p>
    );
  } else {
    return (
      <p
        style={{
          backgroundColor: "#8ce9db",
          color: "#000000",
          padding: "5px 15px 5px 15px",
          borderRadius: "8px",
        }}
      >
        Running
      </p>
    );
  }
};
