import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  InputGroup,
  Input,
  InputRightElement,
  Button,
} from "@chakra-ui/react";
import { BiSearch } from "react-icons/bi";
import Overview from "./Overview/Overview";
import HistoryAd from "./HistoryAd/HistoryAd";
import CreateAd from "./CreateAd/CreateAd";
import { useDispatch, useSelector } from "react-redux";
import { fetchExternalAdData } from "../../../Redux/Advertisement/External/ExternalAdReducer";
import ExterNalAdFilter from "../ExternalAdFilter/ExternalAdFilter";
import { Link, useNavigate } from "react-router-dom";

const ExternalAd = () => {
  const [createAd, setCreateAd] = useState(false);
  const navigate = useNavigate();
  const [type, setType] = useState("Overview");
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [adType, setAdType] = useState("");
  const [releaseStartDate, setReleaseStartDate] = useState("");
  const [releaseEndDate, setReleaseEndDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");

  const dispatch = useDispatch();
  const ad =
    useSelector((store) => store.ExternalAdReducer.externalAd.data) || [];
  const loading =
    useSelector((store) => store?.ExternalAdReducer?.isLoading) || false;
  const { historyCount, overViewCount } = useSelector(
    (store) => store.ExternalAdReducer.externalAd
  );

  useEffect(() => {
    dispatch(
      fetchExternalAdData(
        type,
        search,
        page,
        releaseStartDate,
        releaseEndDate,
        startDate,
        endDate,
        status
      )
    );
  }, [
    search,
    type,
    dispatch,
    page,
    releaseStartDate,
    releaseEndDate,
    startDate,
    endDate,
    status,
  ]);

  if (createAd) {
    return navigate("/External"); //<CreateAd setCreateAd={setCreateAd} />;
  }

  return (
    <div className={styles.MainContainer}>
      <div className={styles.firstDiv}>
        <h1>Advertisement</h1>

        <div className={styles.filterDiv}>
          <InputGroup maxWidth={200} className={styles.searchBar}>
            <Input
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
            />
            <InputRightElement>
              <BiSearch color="green.500" />
            </InputRightElement>
          </InputGroup>
          {/* <Button>Filter  <BiFilter fontSize={20} marginLeft={10} /></Button> */}
          <div className={styles.filterBtn}>
            <ExterNalAdFilter
              type={type}
              page={page}
              search={search}
              setAdType={setAdType}
              adType={adType}
              setReleaseStartDate={setReleaseStartDate}
              releaseStartDate={releaseStartDate}
              setReleaseEndDate={setReleaseEndDate}
              releaseEndDate={releaseEndDate}
              setStartDate={setStartDate}
              startDate={startDate}
              setEndDate={setEndDate}
              endDate={endDate}
              setStatus={setStatus}
              status={status}
            />
            <Link to="/External/CreateAd">
              <Button bg="#8CC9E9">Create New Advertisement</Button>
            </Link>
          </div>
        </div>
      </div>
      <div className={styles.main}>
        <Tabs>
          <TabList className={styles.tabList}>
            <Tab
              className={styles.tabPanel}
              onClick={() => setType("Overview")}
            >
              Overview{" "}
              <span
                id={type === "Overview" ? styles.activeTab : null}
                className={styles.numberSpan}
              >
                {overViewCount}
              </span>
            </Tab>
            &nbsp; &nbsp; &nbsp;
            <Tab className={styles.tabPanel} onClick={() => setType("History")}>
              History{" "}
              <span
                id={type === "History" ? styles.activeTab : null}
                className={styles.numberSpan}
              >
                {historyCount}
              </span>
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel className={styles.tabContent}>
              <Overview
                ad={ad}
                loading={loading}
                setPage={setPage}
                page={page}
              />
            </TabPanel>

            <TabPanel className={styles.tabContent}>
              <HistoryAd
                loading={loading}
                ad={ad}
                setPage={setPage}
                page={page}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};

export default ExternalAd;
