import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import styles from "./style.module.css";
import suggestionList from "../../../../assets/suggestionList.png";
import image1 from "../../../../assets/image1.png";
import location1 from "../../../../assets/location1.png";
import stars from "../../../../assets/stars.png";
import inFeedList from "../../../../assets/inFeedList.png";
import Ad from "../../../../assets/ad.png";
import Cross from "../../../../assets/cross.png";
import { userRequest } from "../../../../requestMethod";
function OverviewPreview({ id, isModalVisible, isOpen, onClose }) {
  console.log("id", id);

  const [adData, setAdData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchAdData = async () => {
      try {
        const response = await userRequest.get(
          `/admin/partner/getSingleAds?_id=${id}`
        );
        const singleAd = response.data.data;
        console.log("singleAd", singleAd);
        setAdData(singleAd);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchAdData();
  }, [id, isModalVisible]);
  const formattedCities = adData?.cities?.join(", ") || adData?.cities;
  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
          <ModalOverlay />
          <ModalContent style={{ borderRadius: "10px" }}>
            <ModalCloseButton />
            <ModalBody>
              <p className="mb-10">
                This ad will be displayed in: [ {formattedCities} ]
              </p>
              <div className={styles.cancelContent}>
                <div className={styles.container2}>
                  {adData?.adType === "Suggestion" && (
                    <div className={styles.imageContainer}>
                      <img
                        src={suggestionList}
                        alt="Suggestion List"
                        className={styles.suggestionImage}
                      />
                      <div className={styles.imageInside}>
                        <div className={styles.previewText}>
                          <p>Here are some </p>
                          <p>suggestions for you !</p>
                          <p>Discover for each place.</p>
                        </div>
                        <div>
                          <div className={styles.previewInputImage}>
                            <img
                              src={
                                adData?.image.length > 0
                                  ? adData?.image
                                  : image1
                              }
                              alt="Uploaded"
                            />
                          </div>
                          <div className={styles.previewAdDetails}>
                            <div className={styles.adPreviewTitle}>
                              <p>{adData?.name}</p>
                            </div>

                            <div className={styles.locations}>
                              <img src={location1} alt="location1" />
                              <p>2km</p>
                            </div>
                          </div>
                          <img
                            src={stars}
                            alt="location1"
                            className={styles.stars}
                          />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                          <div className={styles.previewInputImage}>
                            <img
                              src={
                                adData?.image.length > 0
                                  ? adData?.image
                                  : image1
                              }
                              alt="1"
                            />
                          </div>
                          <div className={styles.previewAdDetails}>
                            <div className={styles.adPreviewTitle}>
                              <p>{adData?.name}</p>
                            </div>

                            <div className={styles.locations}>
                              <img src={location1} alt="location1" />
                              <p>2km</p>
                            </div>
                          </div>
                          <img
                            src={stars}
                            alt="location1"
                            className={styles.stars}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {adData?.adType === "Feed" && (
                    <div className={styles.imageContainer1}>
                      <img
                        src={inFeedList}
                        alt="inFeedList"
                        className={styles.suggestionImage1}
                      />
                      <div className={styles.imageInside1}>
                        <div>
                          <div className={styles.previewInputImage1}>
                            <div className={styles.div1}>
                              <img
                                src={Ad}
                                alt="1"
                                style={{
                                  width: "30px",
                                  position: "absolute",
                                  top: "4%",
                                  left: "4%",
                                  borderRadius: 22,
                                }}
                              />
                              <img
                                src={Cross}
                                alt="1"
                                style={{
                                  width: "30px",
                                  position: "absolute",
                                  right: "4%",
                                  top: "4%",
                                  borderRadius: 22,
                                }}
                              />
                              <div className={styles.imgBox}>
                                <img
                                  src={
                                    adData?.image.length > 0
                                      ? adData?.image
                                      : image1
                                  }
                                  alt="1"
                                />
                              </div>
                              <div className={styles.div2}>
                                <p className={styles.paragraph}>
                                  {adData?.name}
                                </p>
                                <p className={styles.paragraph1}>
                                  {adData?.body}
                                </p>
                              </div>
                              <div className={styles.div3}>
                                <button className={styles.buttons1}>
                                  All Localities
                                </button>
                                <button className={styles.buttons2}>
                                  More Details
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ marginTop: "10px" }}></div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </ModalBody>{" "}
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export default OverviewPreview;
