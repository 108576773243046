import React, { useState } from "react";
import styles from "../styles.module.css";
import { Spinner } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import {
  fetchActivePartnerData,
  updateActivePartnerData,
} from "../../../Redux/Partner/ActivePartnerReducer";
import { Pagination, message } from "antd";
import { Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import ActiveTable from "./ActiveTable";
const Active = ({
  loading,
  partner,
  total,
  setPage,
  setContact,
  setProfile,
  setViewMeatings,
  setProfileId,
  setPartnerId,
  setBusinessId,
  setRouteType,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const updateData = (data) => {
    // console.log("data", data);
    dispatch(updateActivePartnerData(data)).then((res) => {
      console.log("res:", res);
      dispatch(fetchActivePartnerData("Active", "", 1));
      message.error("Membership cancel successfully");
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setPage(page);
  };

  function convertDate(dateStr) {
    try {
      const dateObj = new Date(dateStr);
      const formattedDate = `${dateObj.getUTCDate()}.${
        dateObj.getUTCMonth() + 1
      }.${dateObj.getUTCFullYear()}`;
      return formattedDate;
    } catch (error) {
      return null;
    }
  }

  const handleProfile = (profileId) => {
    setProfile(true);
    setProfileId(profileId);
  };
  const handleContact = (partnerId, type) => {
    console.log("called");
    setContact(true);
    setRouteType(type);
    console.log("partnerId", partnerId);
    setPartnerId(partnerId);
  };
  const handleView = (businessId) => {
    setViewMeatings(true);
    setBusinessId(businessId);
  };

  if (!loading && partner.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          marginTop: "10%",
        }}
      >
        No data available
      </div>
    );
  }

  return (
    <div className={styles.tableDiv}>
      <TableContainer className={styles.tableContainer}>
        <Table className={styles.table} variant="simple">
          <Thead
            style={{
              zIndex: 10,
            }}
          >
            <Tr>
              <Th
                className={styles.tableHead}
                style={{ textTransform: "capitalize" }}
              >
                Name
              </Th>
              <Th
                className={styles.tableHead}
                style={{ textTransform: "capitalize" }}
              >
                Address
              </Th>
              <Th
                className={styles.tableHead}
                style={{ textTransform: "capitalize" }}
              >
                Legal Representative
              </Th>
              <Th
                className={styles.tableHead}
                style={{ textTransform: "capitalize" }}
              >
                No. of Meetings
              </Th>
              <Th
                className={styles.tableHead}
                style={{ textTransform: "capitalize" }}
              >
                Registered on
              </Th>
              <Th
                className={styles.tableHead}
                style={{ textTransform: "capitalize" }}
              >
                Status
              </Th>
              <Th
                className={styles.tableHead}
                style={{ textTransform: "capitalize" }}
              >
                Voucher
              </Th>
              <Th
                className={styles.tableHead}
                style={{ textTransform: "capitalize" }}
              >
                Membership
              </Th>
              <Th
                className={styles.tableHead}
                style={{ textTransform: "capitalize" }}
              >
                Type
              </Th>
              <Th style={{ minWidth: "100px", textTransform: "capitalize" }}>
                Actions
              </Th>
            </Tr>
          </Thead>
          {loading ? (
            <span className={styles.spin}>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="lg"
              />
            </span>
          ) : (
            <Tbody>
              {partner.map((el, i) => (
                <ActiveTable
                  el={el}
                  handleContact={handleContact}
                  handleProfile={handleProfile}
                  handleView={handleView}
                  convertDate={convertDate}
                  updateData={updateData}
                />
              ))}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      {total > 10 && (
        <div className={styles.pagination}>
          <Pagination
            defaultCurrent={1}
            current={currentPage}
            total={total}
            showSizeChanger={false}
            onChange={handlePageChange}
          />
        </div>
      )}
    </div>
  );
};

export default Active;
