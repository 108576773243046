import React, { useState } from "react";
import styles from "../style.module.css";
import DeleteModel from "../../PartnerAd/DeleteModel2";
import { Spinner, useDisclosure } from "@chakra-ui/react";
import Terminate from "../../PartnerAd/Terminate";
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import ExtraOverviewPreview from "./ExtraOverviewPreview";

const Overview = ({ ad, page, loading, type }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedAdId, setSelectedAdId] = useState(null);
  const { isOpen, onClose, onOpen } = useDisclosure();

  const openModal = (id) => {
    console.log("I clicked");
    setSelectedAdId(id?._id);
    setIsModalVisible(true);
    onOpen();
  };
  console.log("isModalVisible", isModalVisible);
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedAdId(null);
    onClose();
  };
  if (!loading && ad.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          marginTop: "10%",
        }}
      >
        No data available
      </div>
    );
  }

  return (
    <div className={styles.tableDiv}>
      <TableContainer
        style={{ border: "1px solid #D9E1E7", borderRadius: "20px" }}
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th style={{ textTransform: "capitalize" }}>Partner Name</Th>
              <Th style={{ textTransform: "capitalize" }}>Ad Name</Th>
              <Th style={{ textTransform: "capitalize" }}>Ad Title</Th>
              <Th style={{ textTransform: "capitalize" }}>Ad body</Th>
              {/* <Th>Ad Type</Th> */}
              <Th style={{ textTransform: "capitalize" }}>Release on</Th>
              <Th style={{ textTransform: "capitalize" }}>Created on</Th>
              <Th style={{ textTransform: "capitalize" }}>Status</Th>
              <Th style={{ textTransform: "capitalize" }}>Action</Th>
            </Tr>
          </Thead>
          {loading ? (
            <span className={styles.spin}>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="lg"
              />
            </span>
          ) : (
            <Tbody>
              {ad.map &&
                ad.map((el, i) => {
                  const status = getStatus(el);
                  console.log("status", status.props.children);
                  return (
                    <Tr key={i} onClick={() => openModal(el)}>
                      <Td>{el?.name}</Td>

                      <Td>{el.name ? el.name : "-"}</Td>
                      <Td>{el.title ? el.title : "-"}</Td>
                      <Td>{el.body ? el.body : "-"}</Td>
                      {/* <Td>{el.adType}</Td> */}
                      <Td>
                        {el.releaseDate ? formatDate(el.releaseDate) : "-"}
                      </Td>
                      <Td>{el.createdAt ? formatDate(el.createdAt) : "-"}</Td>
                      <Td>
                        <p className={styles.blackBtn21}>{getStatus(el)}</p>
                      </Td>
                      <Td>
                        {getStatus(el).props.children === "Running" ? (
                          <Terminate
                            el={el}
                            type={type}
                            page={page}
                            partnerAd={false}
                          />
                        ) : (
                          <DeleteModel
                            el={el}
                            type={type}
                            page={page}
                            partnerAd={false}
                          />
                        )}
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      {isModalVisible && (
        <ExtraOverviewPreview
          id={selectedAdId}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          isOpen={isOpen}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default Overview;

function formatDate(dateStr) {
  try {
    const dateObj = new Date(dateStr);
    const options = { day: "numeric", month: "short", year: "numeric" };
    const formattedDate = dateObj.toLocaleDateString("en-US", options);
    return formattedDate;
  } catch (error) {
    return null;
  }
}

const getStatus = (el) => {
  const currentDate = new Date();
  const releaseDateOnly = el.releaseDate.split("T")[0];
  console.log("releaseDateOnly", releaseDateOnly);
  const releaseDateTime = new Date(`${releaseDateOnly}`);
  if (currentDate < releaseDateTime) {
    return (
      <p
        style={{
          backgroundColor: "#d2eef3",
          color: "#277eac",
          padding: "5px 15px 5px 15px",
          borderRadius: "8px",
        }}
      >
        Starting Soon
      </p>
    );
  } else {
    return (
      <p
        style={{
          backgroundColor: "#8ce9db",
          color: "#000000",
          padding: "5px 15px 5px 15px",
          borderRadius: "8px",
        }}
      >
        Running
      </p>
    );
  }
};
