import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { TbMailFilled } from "react-icons/tb";
import { IoMdCall } from "react-icons/io";
import { userRequest } from "../../../requestMethod";
import { Link, useLocation, useParams } from "react-router-dom";

const ContactDetails = ({ setContact, partnerId, routeType }) => {
  const location = useLocation();
  const params = useParams();
  const from = new URLSearchParams(location.search).get("from");
  const [data, setData] = useState({});
  console.log("partnerId", partnerId);
  const handleContact = () => {
    console.log("I called");
    // setContact(false);
  };
  console.log("data:", data);
  useEffect(() => {
    userRequest(`admin/partner/getSinglePartner?partnerId=${partnerId}`).then(
      (res) => {
        setData(res?.data?.data);
      }
    );
  }, [partnerId]);
  return (
    <div className={styles.MainContainer}>
      <span className={styles.firstSpan}>
        <p onClick={() => setContact(false)}>{routeType}</p> {">"}{" "}
        <b>Legal Representative Contact Details</b>
      </span>
      <h1>
        {data?.fullName} {data?.lastName}
      </h1>
      <p className={styles.sir}>{data?.jobPosition}</p>
      <div className={styles.number}>
        <span className={styles.numSpan}>
          {" "}
          <IoMdCall fontSize={25} />
          <p>{data?.mobile}</p>
        </span>
        <span className={styles.numSpan}>
          {" "}
          <TbMailFilled fontSize={25} />
          <p>{data?.email}</p>
        </span>
      </div>
    </div>
  );
};

export default ContactDetails;
