import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import styles from "./style.module.css";
import cancelImage from "../../../../assets/cancelImage.png";
import { MdCancelPresentation } from "react-icons/md";

function DeleteMembership({ partnerId, partner, updateData }) {
  console.log("partnerName", partner);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const updatedData = {
    partnerId,
    status: "Deleted",
  };
  const handleCancleed = async () => {
    await updateData(updatedData);
  };

  return (
    <>
      <Button
        onClick={onOpen}
        className={styles.deleteButton}
        style={{ padding: 0 }}
      >
        <MdCancelPresentation size={18} />
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
        <ModalOverlay />
        <ModalContent style={{ top: "10%", borderRadius: "10px" }}>
          <ModalCloseButton />
          <ModalBody>
            <div className={styles.crossImage}>
              <img src={cancelImage} alt="" />
            </div>
            <div className={styles.cancelContent}>
              <p>Are your sure want to delete</p>
              <p>the partner of {partner?.name}?</p>
            </div>
          </ModalBody>
          <div className={styles.actionButton}>
            <button onClick={handleCancleed}>Yes</button>
            <button onClick={onClose}>No</button>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}

export default DeleteMembership;
