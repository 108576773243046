import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import styles from "./style.module.css";
import fullAdPreview2 from "../../../../assets/fullAdPreview2.png";
import stars from "../../../../assets/stars.png";
import { userRequest } from "../../../../requestMethod";
import { Link } from "react-router-dom";
function ExtraOverviewPreview({ id, isModalVisible, isOpen, onClose }) {
  console.log("id", id);

  const [adData, setAdData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchAdData = async () => {
      try {
        const response = await userRequest.get(
          `/admin/partner/getSingleExternalAds?_id=${id}`
        );
        const singleAd = response.data.data;
        console.log("singleAd", singleAd);
        setAdData(singleAd);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchAdData();
  }, [id, isModalVisible]);
  const formattedCities = adData?.cities?.join(", ") || adData?.cities;
  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
          <ModalOverlay />
          <ModalContent style={{ borderRadius: "10px" }}>
            <ModalCloseButton />
            <ModalBody>
              <p className="mb-10">
                This ad will be displayed in: [ {formattedCities} ]
              </p>
              <div className={styles.imageContainer1}>
                <img
                  src={fullAdPreview2}
                  alt="Full Screen Ad List"
                  className={styles.suggestionImage}
                />
                <div className={styles.imageInside}>
                  <div className={styles.previewAdDetails}>
                    <div className={styles.adPreviewTitle}>
                      <p>{adData?.name}</p>
                    </div>
                  </div>
                  <img src={stars} alt="location1" className={styles.stars} />
                  <div className={styles.previewAdBody}>
                    <p>{adData?.body}</p>
                  </div>
                  <div className={styles.previewAdButton}>
                    <Link to={adData?.buttonUrl}>
                      <button
                        style={{
                          height: "40px",
                          width: "58%",
                          objectFit: "inherit",
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            paddingTop: "auto",
                            paddingBottom: "auto",
                            fontWeight: "bold",
                            fontSize: "0.8em",
                          }}
                        >
                          More details
                        </span>
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </ModalBody>{" "}
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

export default ExtraOverviewPreview;
