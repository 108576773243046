import React, { useEffect, useState } from "react";
import styles from "../styles.module.css";
import { Avatar, AvatarGroup, Spinner } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchActivePartnerData,
  updateActivePartnerData,
} from "../../../Redux/Partner/ActivePartnerReducer";
import { Pagination, message } from "antd";

import { Link } from "react-router-dom";
import { fetchCurrentVoucherData } from "../../../Redux/Voucher/CurrentVoucherReducer";

const NewPartner = ({
  loading,
  partner,
  total,
  setPage,
  setContact,
  setPartnerId,
  setRouteType,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setPage(page);
  };
  const handleContact = (partnerId, type) => {
    console.log("called");
    setContact(true);
    setRouteType(type);
    console.log("partnerId", partnerId);
    setPartnerId(partnerId);
  };
  const handelUpdate = (data) => {
    try {
      dispatch(updateActivePartnerData(data)).then((res) => {
        dispatch(fetchActivePartnerData("Requested", "", 1));
        if (data.status === "Active") {
          message.success("Request Accepted");
        } else {
          message.error("Request Rejected");
        }
      });
    } catch (error) {
      message.error("Something went wrong");
    }
  };
  function convertDate(dateStr) {
    try {
      const dateObj = new Date(dateStr);
      const formattedDate = `${dateObj.getUTCDate()}.${
        dateObj.getUTCMonth() + 1
      }.${dateObj.getUTCFullYear()}`;
      return formattedDate;
    } catch (error) {
      return null;
    }
  }
  const currentVoucher =
    useSelector(
      (store) => store?.currentVoucherReducer?.currentVoucherData?.data
    ) || [];
  useEffect(() => {
    dispatch(fetchCurrentVoucherData("Current", ""));
  }, []);
  console.log("currentVoucher", currentVoucher);

  if (!loading && partner.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          marginTop: "10%",
        }}
      >
        No data available
      </div>
    );
  }
  console.log("partner", partner);
  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th style={{ minWidth: "100px", maxWidth: "100px" }}>Name</th>
            <th className={styles.tableHead}>Address</th>
            <th className={styles.tableHead}>Submission Date</th>
            <th className={styles.tableHead}>City</th>
            <th className={styles.tableHead}>Category</th>
            <th className={styles.tableHead}>Tax Number</th>
            <th className={styles.tableHead}>Legal Representative</th>
            <th className={styles.tableHead}>Mobile Number</th>
            <th className={styles.tableHead}>Phone Number</th>
            <th className={styles.tableHead}>Membership</th>
            <th className={styles.tableHead}>Images</th>
            <th className={styles.tableHead}>Free Trail</th>
            <th className={styles.tableHead}>Voucher</th>
            <th>Actions</th>
          </tr>
        </thead>

        {loading ? (
          <span className={styles.spin}>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="lg"
            />
          </span>
        ) : (
          <tbody>
            {partner.map((el, i) => {
              const freeTrial = currentVoucher.find(
                (voucher) =>
                  voucher?.code === el?.partnerSubscriptions?.usedVoucher &&
                  voucher?.percentage !== "Buy 1 Get 1 free"
              );
              const freeTrialText = freeTrial
                ? `${el?.partnerSubscriptions?.timePeriod} month${
                    el?.partnerSubscriptions?.timePeriod > 1 ? "s" : ""
                  }`
                : "N/A";
              return (
                <tr key={i}>
                  <td style={{ minWidth: "100px", maxWidth: "100px" }}>
                    {el?.name}
                  </td>

                  <td className={styles.tableHead}>
                    {/* <div className={styles.address}>
                      {el?.address
                        ? el?.address
                            .split(",")
                            ?.map((row, idx) => <span key={idx}>{row}</span>)
                        : "..."}
                    </div> */}
                    <div className={styles.address}>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <span>{el?.addressObject?.street}, </span>
                        <span>{el?.addressObject?.house_number}</span>
                      </p>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <span>{el?.addressObject?.postcode},</span>
                        <span>{el?.addressObject?.city}</span>
                      </p>
                      <p>{el?.addressObject?.country}</p>
                    </div>
                  </td>
                  <td className={styles.tableHead}>
                    {convertDate(el.createdAt)}
                  </td>
                  <td className={styles.tableHead}>{el.city}</td>
                  <td className={styles.tableHead}>{el.category}</td>
                  <td className={styles.tableHead}>{el.taxNumber}</td>
                  <td className={styles.tableHead}>
                    {/* <Link
                      to={`/Partners/${el?.partnerId?._id}?from=New Partner Requests`}
                    >
                      <p className={styles.blackBtn}> Contact Details</p>
                    </Link> */}
                    <p
                      className={styles.blackBtn}
                      onClick={() =>
                        handleContact(el?.partnerId?._id, "New Partners")
                      }
                    >
                      Contact Details
                    </p>
                  </td>
                  <td className={styles.tableHead}>{el?.partnerId?.mobile}</td>
                  <td className={styles.tableHead}>{el.businessTel}</td>
                  <td className={styles.tableHead}>
                    {el.subscription?.planName ?? "N/A"}
                  </td>
                  <td className={styles.tableHead}>
                    {el.photos.length === 1 ? (
                      <Avatar name="Ryan Florence" src={el.photos[0]} />
                    ) : (
                      <AvatarGroup size="md" max={2}>
                        {el.photos.map((pl) => {
                          return <Avatar name="Ryan Florence" src={pl} />;
                        })}
                      </AvatarGroup>
                    )}
                  </td>
                  <td className={styles.tableHead}>{freeTrialText}</td>
                  <td className={styles.tableHead}>
                    {el?.partnerSubscriptions?.usedVoucher ?? "N/A"}
                  </td>

                  <td>
                    <div className={styles.actionDiv}>
                      <p
                        className={styles.blackBtn2}
                        onClick={() =>
                          handelUpdate({
                            partnerId: el?.partnerId?._id,
                            status: "Active",
                            // stripeSubscriptionId:
                            //   el?.partnerSubscriptions?.stripeSubscriptionId,
                          })
                        }
                      >
                        Accept
                      </p>
                      <p
                        className={styles.blackBtn3}
                        onClick={() =>
                          handelUpdate({
                            partnerId: el?.partnerId?._id,
                            status: "Deleted",
                            // stripePaymentId:
                            //   el?.partnerSubscriptions?.stripePaymentId,
                          })
                        }
                      >
                        Reject
                      </p>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      {total > 10 && (
        <div className={styles.pagination}>
          <Pagination
            defaultCurrent={1}
            current={currentPage}
            total={total}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </div>
      )}
    </div>
  );
};

export default NewPartner;
